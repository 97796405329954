import Layout from "../components/Base/Layout";
import React from "react";
import { SEO } from "../components/Base/Seo";
import SetPasswordForm from "../components/ResetPassword/setPasswordForm";
import UnAuthContent from "../components/Login/UnAuthContent";

const SetNewPasswordPage: React.FC = () => {
  return (
    <Layout>
      <UnAuthContent>
        <SetPasswordForm></SetPasswordForm>
      </UnAuthContent>
    </Layout>
  );
};

export const Head = () => (
  <SEO title="UDS Supply" description="Page description" />
);

export default SetNewPasswordPage;
